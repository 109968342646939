import React, { useMemo, useRef, useState } from 'react'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import { pathOr } from 'ramda'
import { countryCodeSelect } from '../../../../../../utils/selectors'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Box, Divider, IconButton, Paper, Stack, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { BUILDER_CDN } from '../../../../../../constants/endpoints'
import { createProvenTheme } from '../../../../../../styles/theme-proven'
import ProvenMap from '../../../../../shared/proven-map'
import { formatPercentage } from '../../../../../../utils/helpers'
import { Image } from 'components/builder/blocks/shared/Image'
import ProvenShape from '../../../../../shared/proven-shape'
import AnimatedNumber from '../../../../../shared/AnimatedNumber'

const theme = createProvenTheme()
const spacing = theme.spacing

const LEFT_SIDE_BACKGROUND_IMG_URL = `${BUILDER_CDN}9be7b3a343374be9b104de2868ae6f69%2F3c59104de7834bf5a4f0b93b351d892b`

function Environment({ environment, airQuality, environmentDescription, waterHardness }) {
  const countryCode = useSelector(countryCodeSelect)
  const zipCode = useSelector(state => pathOr('', ['account', 'info', 'zipCode'], state))
  const stateName = useSelector(state =>
    pathOr('', ['account', 'results', 'user_information', 'location', 'state_name'], state)
  )
  const isLgUp = useMediaQuery(th => th.breakpoints.up('lg'))
  const isMdUp = useMediaQuery(th => th.breakpoints.up('md'))
  const envList = useMemo(() => {
    const list = []
    const uvIndex = environment?.find(env => env.vital_title === 'UV Index')
    if (uvIndex) list.push(uvIndex)
    if (airQuality.length && !isEmpty(airQuality[0])) list.push(airQuality[0])
    if (waterHardness.length && !isEmpty(waterHardness[0])) list.push(waterHardness[0])
    const humidity = environment?.find(env => env.vital_title === 'Humidity')
    if (humidity) list.push(humidity)
    return list
  }, [environment, airQuality, waterHardness])
  const percentInRef = useRef()
  const environtmentRef = useRef()

  const [openedEnvIndex, setOpenedEnvIndex] = useState(null)

  return (
    <>
      <Paper
        sx={{
          position: 'absolute',
          left: 0,
          height: { xs: spacing(30.75), lg: spacing(57.125) },
          width: { xs: spacing(29.625), lg: spacing(59.5) },
          boxShadow: 'unset',
          borderRadius: 'unset',
          mixBlendMode: 'multiply',
          transform: isLgUp ? `translateY(${spacing(11.5)})` : `translateY(${spacing(12)})`
        }}
      >
        <Image
          lazy
          image={LEFT_SIDE_BACKGROUND_IMG_URL}
          aspectRatio={1.0416}
          backgroundSize={!isLgUp ? 'contain' : 'cover'}
          backgroundPosition={
            isLgUp ? `top 0 left ${spacing(-29.75)}` : `top 0 left ${spacing(-6.25)}`
          }
        />
      </Paper>
      <Box
        pt={{ xs: 24, md: 12 }}
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: 'repeat(4, 1fr)', md: 'repeat(12, 1fr)' },
          gridTemplateRows: {
            xs: 'auto auto auto 1fr',
            md: 'auto auto 1fr',
            lg: 'auto auto 1fr'
          },
          gridTemplateAreas: {
            xs: `"title title title title"
                 "description description description description"
                 "percentage percentage percentage percentage"
                 "map map map map"`,
            md: `"title title title title title title map map map map map map"
                 "description description description description description description map map map map map map"
                 "percentage percentage percentage percentage percentage percentage map map map map map map"`,
            lg: `"title title title title title . map map map map map map"
                 "description description description description description . map map map map map map"
                 "percentage percentage percentage percentage percentage . map map map map map map"`
          },
          columnGap: 3
        }}
      >
        <Typography
          zIndex={4}
          alignSelf="end"
          sx={{ gridArea: 'title' }}
          pb={{ xs: 2 }}
          pt={{ xs: 0, md: 13.5 }}
          variant="h2"
        >
          Made for Your
          <br />
          Environment
          <br />
          {zipCode && (
            <Typography
              component="span"
              variant="inherit"
              color="primary.main"
              ref={environtmentRef}
            >
              {zipCode}
            </Typography>
          )}
        </Typography>
        <Typography
          zIndex={4}
          sx={{ gridArea: 'description', pb: 2 }}
          variant="body2"
          color="gray.blueGray"
        >
          {environmentDescription && environmentDescription?.trim()
            ? environmentDescription
            : `Your environment has a major impact on your skin. Your personalized skincare accounts for the UV index, pollution type & level, water hardness and humidity of your municipality.`}
        </Typography>

        <Box gridArea="percentage" mb={{ xs: 6, lg: 0 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ProvenShape
              zIndex={1}
              baseWidth={isMdUp ? 3.6 : 3}
              baseHeight={isMdUp ? 3.6 : 3}
              viewBox="0 0 32 32"
              name="circle"
              backgroundColor="gray.lightBlue"
              justifyContent="flex-start"
            >
              <Typography
                pr={2}
                pl={2}
                zIndex={4}
                variant="h3"
                color="primary.main"
                display="flex"
                alignItems="center"
                ref={percentInRef}
              >
                <AnimatedNumber value={92} parentRef={percentInRef} />%
              </Typography>
            </ProvenShape>
            <Typography alignSelf="start" zIndex={4} variant="title2" color="primary.main">
              of customers require seasonal adjustments to their skincare routine
            </Typography>
          </Box>
        </Box>

        <Box
          gridArea="map"
          sx={{
            boxShadow: 3,
            py: 3,
            px: { xs: 2, md: 3 },
            borderRadius: 2,
            background: 'white',
            overflow: 'hidden'
          }}
        >
          <ProvenMap
            zipCode={zipCode}
            countryCode={countryCode}
            state={stateName}
            googleMapWidth={914}
            googleMapHeight={706}
          />

          <Box px={isMdUp ? 2 : 0}>
            <Typography
              variant="title1"
              color="gray.elysian"
              component="div"
              sx={{ my: { xs: 2, md: 3 } }}
            >
              Environmental Factors
            </Typography>
            {envList.map((env, i) => {
              const isOpened = openedEnvIndex === i

              return (
                <Box zIndex={4} key={`env${i}`} mt={2}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => setOpenedEnvIndex(isOpened ? null : i)}
                  >
                    <Stack direction="row">
                      <Box
                        sx={{
                          width: spacing(4),
                          mr: 2,
                          height: 'auto',
                          position: 'relative'
                        }}
                      >
                        <Image
                          lazy
                          backgroundSize="contain"
                          image={env.vital_icon_link}
                          altText={env.vital_img_name}
                          aspectRatio={1}
                        />
                      </Box>
                      <Stack direction="column" sx={{ textTransform: 'uppercase' }}>
                        <Typography variant="title1" color="gray.elysian">
                          {env.vital_title}
                        </Typography>
                        {env.slider_value && (
                          <Typography variant="footnoteLight" color="gray.blueGray">
                            {env.slider_value}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                    <IconButton size="small" sx={{ color: theme.palette.gray.elysian }}>
                      {isOpened ? <RemoveIcon /> : <AddIcon />}
                    </IconButton>
                  </Box>

                  {isOpened && (
                    <Box pl={6}>
                      <Typography variant="body2" color="gray" my={2}>
                        {env.body}
                      </Typography>
                      <Stack flexWrap="wrap" direction={isLgUp ? 'row' : 'column'} gap={3}>
                        {(env.ingredients || [])
                          .filter(ing => ing && ing.ingredient_img)
                          .slice(0, 3)
                          .map((ingredient, index) => (
                            <Stack
                              key={`env${i}-ing${index}`}
                              gap={isLgUp ? 3 : 2}
                              pb={isLgUp ? 1 : 0}
                              direction="row"
                              alignItems="center"
                              width={isLgUp ? 'calc(50% - 12px)' : '100%'}
                            >
                              <Box>
                                <Image
                                  lazy
                                  image={ingredient.ingredient_img}
                                  altText={ingredient.ingredient_name}
                                  sx={{
                                    width: { xs: spacing(6.5), md: spacing(9) },
                                    height: 'auto',
                                    maxHeight: { xs: spacing(6.5), md: spacing(9) }
                                  }}
                                />
                              </Box>

                              <Typography component="p" variant="footnote" color="gray.elysian">
                                {ingredient.ingredient_name}
                                <Typography
                                  component="p"
                                  variant="title1"
                                  color="gray.blueGray"
                                  mt={0.5}
                                >
                                  {formatPercentage(ingredient.ingredient_percentage)}
                                </Typography>
                              </Typography>
                            </Stack>
                          ))}
                      </Stack>
                    </Box>
                  )}

                  {i < envList.length - 1 && (
                    <Divider
                      sx={{
                        borderColor: theme.palette.gray.elysian,
                        opacity: 0.2,
                        mt: 2
                      }}
                    />
                  )}
                </Box>
              )
            })}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Environment
